import axiosConfig from './config/axiosConfig';

import { WEMS_QUERY } from './config/constants';

export default function wemsQueryApi() {
  return {
    async cancel(params) {
      const query = await axiosConfig.post(
        `${WEMS_QUERY}/cancel-batch-command-request`,
        params
      );
      return query;
    },
    async getAll(search) {
      const query = await axiosConfig.get(
        `${WEMS_QUERY}/command-request${search}`
      );
      return query;
    },
    async add(params) {
      const query = await axiosConfig.post(
        `${WEMS_QUERY}/command-request`,
        params
      );
      return query;
    },
    async getOne(batchId) {
      const requestRes = await axiosConfig.get(
        `${WEMS_QUERY}/command-request/${batchId}`
      );

      const {
        data: { sites, ...restData }
      } = requestRes.data;

      return {
        ...restData,
        siteIds: sites.map(site => site.id)
      };
    },
    async export(batchId) {
      const query = await axiosConfig.get(
        `${WEMS_QUERY}/command-request/export/${batchId}`
      );
      return query;
    },
    async getCommandRequestTasks(batchId, search) {
      const query = await axiosConfig.get(
        `${WEMS_QUERY}/command-request-tasks/${batchId}${search}`
      );
      return query;
    },
    async retryReport(batchId) {
      const query = await axiosConfig.post(
        `${WEMS_QUERY}/command-request-tasks/${batchId}/retry`
      );
      return query;
    }
  };
}
