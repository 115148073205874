import isSameDay from 'date-fns/isSameDay';
import format from 'date-fns/format';

export function formatUTCIntoString(date) {
  return format(date, 'OOOO').replace('GMT', 'UTC');
}
export function soustractDays(days) {
  return new Date(Date.now() - days * 24 * 60 * 60 * 1000);
}

export function formatDateIntoString(date) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }

  return [year, month, day].join('-');
}

export function formatDateFromToIntoString(dateStart, dateEnd) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };

  const from = new Date(dateStart).toLocaleDateString('fr-FR', options);
  const to = new Date(dateEnd).toLocaleDateString('fr-FR', options);

  return `du ${from} au ${to}`;
}

export function formatDateFromToIntoPeriodString(dateStart, dateEnd) {
  const from = new Date(dateStart);
  const to = new Date(dateEnd);

  if (isSameDay(from, to)) {
    return `le ${format(from, 'dd/MM/yyyy')} de ${format(
      from,
      'HH:mm'
    )} à ${format(to, 'HH:mm')}`;
  }

  return `le ${format(from, 'dd/MM/yyyy à HH:mm')} au ${format(
    to,
    'dd/MM/yyyy à HH:mm'
  )}`;
}

export function formatDateToString(date) {
  return new Date(date).toLocaleString();
}
