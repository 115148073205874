import React, { lazy } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { ENEDIS_ALERTS_PATH } from 'eficia/constants/paths';
import PrivateRoute from 'eficia/routes/PrivateRoute';

// enedis-alert
const EnedisAlertsManagementHomePage = lazy(() =>
  import('eficia/views/enedis-alerts/EnedisAlertsManagementHomePage')
);

const EnedisAlertsManagementCreatePage = lazy(() =>
  import('eficia/views/enedis-alerts/EnedisAlertsManagementCreatePage')
);

const EnedisAlertsSubscriptionHomePage = lazy(() =>
  import('eficia/views/enedis-alerts/EnedisAlertsSubscriptionHomePage')
);

const EnedisAlertsManagementEditPage = lazy(() =>
  import('eficia/views/enedis-alerts/EnedisAlertsManagementEditPage')
);

const EnedisAlertsHistoricHomePage = lazy(() =>
  import('eficia/views/enedis-alerts/historic/EnedisAlertsHistoricHomePage')
);

const EnedisAlertsHistoricDetailsPage = lazy(() =>
  import('eficia/views/enedis-alerts/historic/EnedisAlertsHistoricDetailsPage')
);

export default function EnedisAlertRoutes() {
  return (
    <PrivateRoute
      path={ENEDIS_ALERTS_PATH}
      render={({ match: { path }, location: { pathname: currentPath } }) => (
        <>
          {currentPath === path && <Redirect to={`${path}/management`} />}
          <Route
            path={`${path}/management`}
            component={EnedisAlertsManagementHomePage}
            exact
          />
          <Route
            path={`${path}/management/create`}
            component={EnedisAlertsManagementCreatePage}
          />
          <Route
            path={`${path}/management/edit/:enedisAlertId`}
            component={EnedisAlertsManagementEditPage}
          />
          <Route
            path={`${path}/subscription`}
            component={EnedisAlertsSubscriptionHomePage}
          />
          <Route
            path={`${path}/historic`}
            component={EnedisAlertsHistoricHomePage}
            exact
          />
          <Route
            path={`${path}/historic/:alertId/:siteId`}
            component={EnedisAlertsHistoricDetailsPage}
          />
        </>
      )}
    />
  );
}
