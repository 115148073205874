import axiosConfig from './config/axiosConfig';
import { GRDF_SERVICE_SUBSCRIPTION_API } from './config/constants';

export default function grdfServiceSubscriptionsApi() {
  return {
    async getGrdfList() {
      const query = await axiosConfig.get(
        `${GRDF_SERVICE_SUBSCRIPTION_API}/site-list`
      );
      return query;
    },
    async create(params) {
      const query = await axiosConfig.post(
        `${GRDF_SERVICE_SUBSCRIPTION_API}/mass-access-right/declaration`,
        params
      );
      return query;
    },
    async renewAccessRight(params) {
      const query = await axiosConfig.post(
        `${GRDF_SERVICE_SUBSCRIPTION_API}/renew-access-right`,
        params
      );
      return query;
    },
    async cancelAccessRight(params) {
      const query = await axiosConfig.post(
        `${GRDF_SERVICE_SUBSCRIPTION_API}/mass-access-right-revocation`,
        params
      );
      return query;
    },
    async getOneDemandReport(demandId) {
      const query = await axiosConfig.get(
        `${GRDF_SERVICE_SUBSCRIPTION_API}/mass-access-right/${demandId}`
      );
      return query;
    },
    async getOneDemandReportList(massAccessRightOperationId, search) {
      const query = await axiosConfig.get(
        `${GRDF_SERVICE_SUBSCRIPTION_API}/mass-access-right-report/${massAccessRightOperationId}${search}`
      );
      return query;
    },
    async getAllAccessRight(search, params) {
      const query = await axiosConfig.post(
        `${GRDF_SERVICE_SUBSCRIPTION_API}/access-rights${search}`,
        params
      );
      return query;
    },
    async exportReportList(demandId) {
      const query = await axiosConfig.get(
        `${GRDF_SERVICE_SUBSCRIPTION_API}/mass-access-right/${demandId}/export-csv`
      );
      return query;
    },
    async getAllDemands(search) {
      const query = await axiosConfig.get(
        `${GRDF_SERVICE_SUBSCRIPTION_API}/mass-access-rights${search}`
      );
      return query;
    }
  };
}
