/* eslint-disable no-return-assign */
import React, { lazy, Suspense } from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import { UserProvider } from 'eficia/contexts/UserProvider';
import { NotificationProvider } from 'eficia/contexts/NotificationProvider';
import { LocationProvider } from 'eficia/contexts/LocationProvider';
import { TranslationProvider } from 'eficia/contexts/TranslationProvider';
import PulseModal from 'eficia/components/organisms/Modal/PulseModal';

// import routes
import AlertRoutes from 'eficia/routes/pages/alert';
import AlertBisRoutes from 'eficia/routes/pages/alertBis';
import ElectricityCurveRoutes from 'eficia/routes/pages/electricityCurve';
import ElectricityCurveMonositeRoutes from 'eficia/routes/pages/electricityCurveMonosite';
import EnedisAlertRoutes from 'eficia/routes/pages/enedisAlert';
import EnedisServiceSubscriptionRoutes from 'eficia/routes/pages/enedisServiceSubscription';
import HarmonyScriptRoutes from 'eficia/routes/pages/harmonyScript';
import HarmonyWritingRoutes from 'eficia/routes/pages/harmonyWriting';
import HistoryRequestRoutes from 'eficia/routes/pages/historyRequest';
import OncallRoutes from 'eficia/routes/pages/onCall';
import SoftwareByAlricRoutes from 'eficia/routes/pages/softwareByAlric';
import TranslationRoutes from 'eficia/routes/pages/translation';
import WemsQueryRoutes from 'eficia/routes/pages/wemsQuery';
import GrdfServiceSubscriptionRoutes from 'eficia/routes/pages/grdfServiceSubscription';

import LeftSidebar from 'eficia/views/template/LeftSideBar';

const EnedisAlertsEmailUnscubscribePage = lazy(() =>
  import('eficia/views/enedis-alerts/email/EnedisAlertsEmailUnsubscribePage')
);

const ForbiddenAccessPage = lazy(() =>
  import('eficia/views/error/ForbiddenAccessPage')
);

const Routes = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const HOME_PAGE_PATH = 'alerts/management';

  return (
    <AnimatePresence>
      <Suspense fallback={<PulseModal />}>
        <Switch>
          {/* pages sans authentification */}
          <Route path={['/forbidden-access', '/enedis-alerts-unsubscribe']}>
            <>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                >
                  <Route
                    path="/forbidden-access"
                    component={ForbiddenAccessPage}
                  />
                  <Route
                    path="/enedis-alerts-unsubscribe"
                    component={EnedisAlertsEmailUnscubscribePage}
                  />
                </motion.div>
              </Switch>
            </>
          </Route>

          {/* pages avec authentification Jwt */}
          <UserProvider>
            <TranslationProvider>
              <NotificationProvider>
                <LocationProvider>
                  <Route>
                    <LeftSidebar>
                      <Switch location={location} key={location.pathname}>
                        <motion.div
                          initial="initial"
                          animate="in"
                          exit="out"
                          variants={pageVariants}
                        >
                          <Route exact path="/">
                            <Redirect to={HOME_PAGE_PATH} />
                          </Route>

                          <AlertRoutes />
                          <AlertBisRoutes />
                          <ElectricityCurveRoutes />
                          <ElectricityCurveMonositeRoutes />
                          <EnedisAlertRoutes />
                          <EnedisServiceSubscriptionRoutes />
                          <HarmonyScriptRoutes />
                          <HarmonyWritingRoutes />
                          <HistoryRequestRoutes />
                          <OncallRoutes />
                          <SoftwareByAlricRoutes />
                          <TranslationRoutes />
                          <WemsQueryRoutes />
                          <GrdfServiceSubscriptionRoutes />
                        </motion.div>
                      </Switch>
                    </LeftSidebar>
                  </Route>
                </LocationProvider>
              </NotificationProvider>
            </TranslationProvider>
          </UserProvider>
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
};

export default Routes;
