import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  ALERTS_BIS_HOME_PATH,
  ALERT_HOME_PATH,
  ON_CALL_HOME_PATH,
  HISTORY_REQUESTS_HOME_PATH,
  TRANSLATION_HOME_PATH,
  SOFTWARE_BY_ALRIC_HOME_PATH,
  HARMONY_SCRIPTS_PATH,
  WEMS_QUERY_PATH,
  ENEDIS_ALERTS_PATH,
  ENEDIS_SERVICE_SUBSCRIPTION_PATH,
  ELETRICITY_CURVES_PATH,
  ELETRICITY_CURVES_MONOSITE_PATH,
  HARMONY_WRITING_PATH,
  GRDF_SERVICE_SUBSCRIPTION_PATH
} from 'eficia/constants/paths';

export const NAV_CONFIG = [
  {
    label: 'Menu',
    content: [
      {
        label: 'alert.menu.label',
        icon: <FontAwesomeIcon icon={['fas', 'bell']} />,
        slug: 'alerts',
        to: ALERT_HOME_PATH,
        content: [
          {
            label: 'alert.management.menu.label',
            to: `${ALERT_HOME_PATH}/management`
          },
          {
            label: 'alert.consultation.menu.label',
            to: `${ALERT_HOME_PATH}/consultation`
          },
          {
            label: 'alert.subscription.menu.label',
            to: `${ALERT_HOME_PATH}/subscription`
          }
        ]
      },
      {
        label: 'enedis_alerts.sidebar.menu.label',
        icon: <FontAwesomeIcon icon={['fas', 'bell']} />,
        slug: 'enedis-alerts',
        to: ENEDIS_ALERTS_PATH,
        content: [
          {
            label: 'enedis_alerts.sidebar.management.menu.label',
            to: `${ENEDIS_ALERTS_PATH}/management`
          },
          {
            label: 'enedis_alerts.sidebar.subscription.menu.label',
            to: `${ENEDIS_ALERTS_PATH}/subscription`
          },
          {
            label: 'enedis_alerts.sidebar.historic.menu.label',
            to: `${ENEDIS_ALERTS_PATH}/historic`
          }
        ]
      },
      {
        label: 'enedis_services.sidebar.menu.label',
        icon: <FontAwesomeIcon icon={['fas', 'bell']} />,
        slug: 'enedis-services-subscription',
        to: ENEDIS_SERVICE_SUBSCRIPTION_PATH,
        content: [
          {
            label: 'enedis_services.sidebar.demand.menu.label',
            to: `${ENEDIS_SERVICE_SUBSCRIPTION_PATH}/demands`
          },
          {
            label: 'enedis_services.sidebar.service.menu.label',
            to: `${ENEDIS_SERVICE_SUBSCRIPTION_PATH}/services`
          }
        ]
      },
      {
        label: 'alert_bis.menu.label',
        icon: <FontAwesomeIcon icon={['fas', 'bell']} />,
        to: ALERTS_BIS_HOME_PATH,
        slug: 'alerts-bis'
      },
      {
        label: 'history.menu.label',
        icon: <FontAwesomeIcon icon={['fas', 'chart-bar']} />,
        to: HISTORY_REQUESTS_HOME_PATH,
        slug: 'history-requests'
      },
      {
        label: 'on_call.menu.label',
        icon: <FontAwesomeIcon icon={['fas', 'phone-volume']} />,
        to: ON_CALL_HOME_PATH,
        slug: 'oncall-spain'
      },
      {
        label: 'translation.menu.label',
        icon: <FontAwesomeIcon icon={['fas', 'globe-europe']} />,
        to: TRANSLATION_HOME_PATH,
        slug: 'translation'
      },
      {
        label: 'Logiciels by Alric',
        icon: <FontAwesomeIcon icon={['fas', 'window-restore']} />,
        to: SOFTWARE_BY_ALRIC_HOME_PATH,
        slug: 'software-by-alric'
      },
      {
        label: 'harmony_script.sidebar.menu.label',
        icon: <FontAwesomeIcon icon={['fas', 'edit']} />,
        to: HARMONY_SCRIPTS_PATH,
        slug: 'harmony-scripts'
      },
      {
        label: 'wems_query.sidebar.menu.label',
        icon: <FontAwesomeIcon icon={['fas', 'edit']} />,
        to: WEMS_QUERY_PATH,
        slug: 'wems-query'
      },
      {
        label: 'electricity_curve.menu.label',
        icon: <FontAwesomeIcon icon={['fas', 'chart-bar']} />,
        to: ELETRICITY_CURVES_PATH,
        slug: 'electricity-curves'
      },
      {
        label: 'electricity_curve_monosite.menu.label',
        icon: <FontAwesomeIcon icon={['fas', 'chart-bar']} />,
        to: ELETRICITY_CURVES_MONOSITE_PATH,
        slug: 'electricity-curves-monosite'
      },
      {
        label: 'harmony_writing.menu.label',
        icon: <FontAwesomeIcon icon={['fas', 'bell']} />,
        to: HARMONY_WRITING_PATH,
        slug: 'harmony-writing'
      },
      {
        label: 'grdf_services.sidebar.menu.label',
        icon: <FontAwesomeIcon icon={['fas', 'bell']} />,
        slug: 'grdf-services-subscription',
        to: GRDF_SERVICE_SUBSCRIPTION_PATH,
        content: [
          {
            label: 'grdf_services.sidebar.demand.menu.label',
            to: `${GRDF_SERVICE_SUBSCRIPTION_PATH}/demands`
          },
          {
            label: 'grdf_services.sidebar.access_right.menu.label',
            to: `${GRDF_SERVICE_SUBSCRIPTION_PATH}/access_rights`
          }
        ]
      }
    ]
  }
];
