import React, { lazy } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { GRDF_SERVICE_SUBSCRIPTION_PATH } from 'eficia/constants/paths';
import PrivateRoute from 'eficia/routes/PrivateRoute';

const GrdfDemandListPage = lazy(() =>
  import('eficia/views/grdf-service-subscription/GrdfDemandListPage')
);
const GrdfCreateDemandPage = lazy(() =>
  import('eficia/views/grdf-service-subscription/GrdfDemandCreatePage')
);

const GrdfDemandReportPage = lazy(() =>
  import('eficia/views/grdf-service-subscription/GrdfDemandReportPage')
);

const GrdfAccessRightListPage = lazy(() =>
  import('eficia/views/grdf-service-subscription/GrdfAccessRightListPage')
);

export default function GrdfServiceSubscriptionRoutes() {
  return (
    <PrivateRoute
      path={GRDF_SERVICE_SUBSCRIPTION_PATH}
      render={({ match: { path }, location: { pathname: currentPath } }) => (
        <>
          {currentPath === path && <Redirect to={`${path}/demands`} />}
          <Route
            path={`${path}/demands`}
            component={GrdfDemandListPage}
            exact
          />
          <Route
            path={`${path}/demands/report/:demandId`}
            component={GrdfDemandReportPage}
          />
          <Route
            path={`${path}/demands/create`}
            component={GrdfCreateDemandPage}
            exact
          />
          <Route
            path={`${path}/access_rights`}
            component={GrdfAccessRightListPage}
          />
        </>
      )}
    />
  );
}
