import { streamDownload } from 'eficia/utilities/streamDownload';

import axiosConfig from './config/axiosConfig';

import { ENEDIS_ALERTS_API } from './config/constants';

export default function enedisAlertsApi() {
  return {
    async add(params) {
      const query = await axiosConfig.post(
        `${ENEDIS_ALERTS_API}/alert`,
        params
      );
      return query;
    },
    async editAlert(params) {
      const query = await axiosConfig.put(`${ENEDIS_ALERTS_API}/alert`, params);
      return query;
    },
    async editSiteAlert(params) {
      const query = await axiosConfig.put(
        `${ENEDIS_ALERTS_API}/site-alert`,
        params
      );
      return query;
    },
    async getAllGrouped(search, params) {
      const query = await axiosConfig.post(
        `${ENEDIS_ALERTS_API}/alerts${search}`,
        params
      );
      return query;
    },
    async getAllExpanded(search, params) {
      const query = await axiosConfig.post(
        `${ENEDIS_ALERTS_API}/site-alerts${search}`,
        params
      );
      return query;
    },
    async getOne(enedisAlertId) {
      const query = await axiosConfig.get(
        `${ENEDIS_ALERTS_API}/alert/${enedisAlertId}`
      );
      return query;
    },
    async deleteAlerts(alertIds) {
      const query = axiosConfig.delete(`${ENEDIS_ALERTS_API}/alert`, {
        data: alertIds
      });
      return query;
    },
    async deleteSiteAlerts(siteAlertIds) {
      const query = axiosConfig.delete(`${ENEDIS_ALERTS_API}/site-alert`, {
        data: siteAlertIds
      });
      return query;
    },
    async getAllSubscriptions(search, params) {
      const query = axiosConfig.post(
        `${ENEDIS_ALERTS_API}/alert/subscriptions${search}`,
        params
      );
      return query;
    },
    async subscribeAlerts(params) {
      const query = axiosConfig.post(
        `${ENEDIS_ALERTS_API}/site-alert/subscription`,
        params
      );
      return query;
    },
    async deleteSubscriptions(siteSubscriptions) {
      const query = axiosConfig.delete(
        `${ENEDIS_ALERTS_API}/site-alert/subscription`,
        {
          data: { siteSubscriptions }
        }
      );
      return query;
    },
    async unsubscribeAlertSubscription(subscriptionIds) {
      const query = axiosConfig.delete(
        `${ENEDIS_ALERTS_API}/alert/unsubscribe`,
        {
          data: { subscriptionIds }
        }
      );
      return query;
    },
    async getHistorics(search, params) {
      const query = axiosConfig.post(
        `${ENEDIS_ALERTS_API}/alert/power-exceedings${search}`,
        params
      );
      return query;
    },
    async exportHistorics(params) {
      return streamDownload(
        `${ENEDIS_ALERTS_API}/alert/export-power-exceedings`,
        params
      );
    },
    async exportHistoricDetails(params) {
      return streamDownload(
        `${ENEDIS_ALERTS_API}/site-alerts/power-exceeding-details/export-csv`,
        params
      );
    },

    async getHistoricDetails(alertId, siteId, search) {
      const query = axiosConfig.get(
        `${ENEDIS_ALERTS_API}/alert/power-exceedings/${alertId}/${siteId}${search}`
      );
      return query;
    }
  };
}
