import { formatDateToString } from 'eficia/utilities/date';

import { GROUPED_TYPE_OF_VIEW } from 'eficia/views/alert/constants/AlertConstants';
import {
  ADDRESS_TYPE,
  TAG_TYPE
} from 'eficia/views/alert/constants/AlertRulesConstants';
import { getRuleName } from 'eficia/views/alert/utils';

import axiosConfig from './config/axiosConfig';

import { ALERT_API } from './config/constants';

export default function alertsApi() {
  return {
    // view type: 'grouped'|'expanded'
    async getAll(viewType, search, params) {
      const requestRes = await axiosConfig.post(
        `${ALERT_API}/${viewType}${search}`,
        params
      );

      const {
        data,
        meta: { pagination }
      } = requestRes.data;

      return {
        data: data.map(({ alertCreatedAt, siteAlertCreatedAt, ...rest }) => ({
          createdDate: formatDateToString(
            viewType === GROUPED_TYPE_OF_VIEW
              ? alertCreatedAt
              : siteAlertCreatedAt
          ),
          ...rest
        })),
        pagination: {
          countPage: pagination.total_pages,
          itemsCount: pagination.total
        }
      };
    },
    async getOne(alertId, format = false, currentLangCode = 'fr') {
      const result = await axiosConfig.get(`${ALERT_API}/${alertId}`);

      if (!format) {
        return result;
      }

      const { alertContacts, alertRuleGroups, ...rest } = result.data;

      async function getRequest({
        rules: { objectAddresses, objectTags },
        payload,
        name,
        type
      }) {
        const addresses = objectAddresses
          ? objectAddresses.data.map(({ value }) => value)
          : [];
        const tags = objectTags
          ? objectTags.data.map(({ value }) => value)
          : [];

        const descriptionRes = await axiosConfig
          .post(`${ALERT_API}/generate-rule-label`, {
            locale: currentLangCode,
            ruleType: type,
            ruleName: name,
            addresses,
            tags,
            rulePayload: JSON.stringify(payload)
          })
          .then(function(response) {
            return response.data.data.label;
          });

        return descriptionRes;
      }

      const ruleDescriptionPromises = alertRuleGroups.data
        .map(ruleGroup => ruleGroup.alertRules.data.map(getRequest))
        .reduce((a, b) => a.concat(b), []);

      const descriptionArray = await Promise.all(ruleDescriptionPromises);

      return {
        emails: alertContacts.data.map(({ value }) => value),
        ruleGroups: alertRuleGroups.data.map((ruleGroup, groupIndex) =>
          ruleGroup.alertRules.data.map(
            (
              {
                rules: { objectAddresses, objectTags },
                payload: { optional, required },
                name,
                type,
                notifyEachObject
              },
              ruleIndex
            ) => {
              return {
                description: descriptionArray.shift(),
                address: objectAddresses
                  ? objectAddresses.data.map(({ value }) => value)
                  : [],
                index: groupIndex + ruleIndex,
                rule: getRuleName(name),
                ruleOperatorName: name,
                ruleType: type,
                tags: objectTags
                  ? objectTags.data.map(({ value }) => value)
                  : [],
                notifyEachObject,
                type:
                  objectTags && objectTags.data.length !== 0
                    ? TAG_TYPE
                    : ADDRESS_TYPE,
                ...optional,
                ...required
              };
            }
          )
        ),
        alertContacts,
        alertRuleGroups,
        ...rest
      };
    },
    async add(params) {
      const query = await axiosConfig.post(ALERT_API, params);
      return query;
    },
    async edit(params) {
      const query = await axiosConfig.put(ALERT_API, params);
      return query;
    },
    async delete(alertIds) {
      const query = await axiosConfig.delete(ALERT_API, {
        data: {
          alertIds
        }
      });
      return query;
    },
    async getAllSubscriptions(search, params) {
      const query = axiosConfig.post(
        `${ALERT_API}/subscriptions${search}`,
        params
      );
      return query;
    },
    async subscribeAlerts(params) {
      const query = axiosConfig.post(`${ALERT_API}/subscribe`, params);
      return query;
    },
    async deleteSubscriptions(subscriptionIds) {
      const query = axiosConfig.delete(`${ALERT_API}/unsubscribe`, {
        data: {
          subscriptionIds
        }
      });
      return query;
    }
  };
}
