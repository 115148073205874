export const ALERT_HOME_PATH = '/alerts';
export const ENEDIS_ALERTS_PATH = '/enedis-alerts';

// Alerts bis Page
export const ALERTS_BIS_HOME_PATH = '/alerts-bis';

// On call
export const ON_CALL_HOME_PATH = '/oncall-spain';

// History Request
export const HISTORY_REQUESTS_HOME_PATH = '/history-requests';

export const TRANSLATION_HOME_PATH = '/translation';

export const SOFTWARE_BY_ALRIC_HOME_PATH = '/software-by-alric';

export const HARMONY_SCRIPTS_PATH = '/harmony-scripts';

export const WEMS_QUERY_PATH = '/wems-query';

export const ELETRICITY_CURVES_PATH = '/electricity-curves';

export const ELETRICITY_CURVES_MONOSITE_PATH = '/electricity-curves-monosite';

export const ENEDIS_SERVICE_SUBSCRIPTION_PATH = '/enedis-services-subscription';

export const HARMONY_WRITING_PATH = '/harmony-writing';

export const GRDF_SERVICE_SUBSCRIPTION_PATH = '/grdf-services-subscription';

// Global Login
export const GLOBAL_V2_LOGIN = 'https://global.ensylog.com/login.php';
